*{
  font-family: Lucida Console;
  background-color: #EFF8FF;
}

.value {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  max-width: 50%;
  min-width: 25px;
  min-height: 25px;
  margin: auto;
}

/* On mouse-over, add a deeper shadow */
.value:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.5);
}

.suit {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 50px;
  height: 50px;
}

.suit:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,1);
}

/* Add some padding inside the card container */
.container {
  padding: 3px 1px;
  margin: auto;
  min-width: 50px;
}

.playing-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 100px;
  height: 150px;
  margin: auto;
}

.playing-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,1);
}

.flexbox-container-header-footer {
  display: flex;
  justify-content: center;
  text-align: center;
}

.flexbox-container-1 {
  display: flex;
  justify-content: center;
  text-align: center;
}

.flexbox-container-2 {
  display: flex;
  justify-content: center;
}

.flexbox-item-1 {
  width: 150px;
  margin: 10px;
  border: 3px solid #333
}

.flexbox-item-2 {
  width: 560px;
  margin: 10px;
  border: 3px solid #333;
}

a:link {
  text-decoration: none;
}